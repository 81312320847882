<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">  
            <el-form :inline="true">
                <el-form-item label="商品id：">
                    <el-input v-model="search_id" placeholder="请输入商品id"></el-input>
                </el-form-item>
                <el-form-item label="商品标题：">
                    <el-input v-model="search_title" placeholder="请输入商品标题"></el-input>
                </el-form-item>
                <el-form-item label="平台：">
                    <el-select v-model="search_platform" placeholder="请选择">
                        <el-option
                        v-for="item in platform_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="免单类型：">
                    <el-select v-model="search_free_type" placeholder="请选择">
                        <el-option
                        v-for="item in free_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="状态：">
                    <el-select v-model="search_status" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="上架" value="1"></el-option>
                        <el-option label="下架" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="btn-box">
            <el-button type="primary" size="mini" @click="open">添加</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="freeOrderList"
            
            border>
            <el-table-column
                fixed
                prop="id"
                label="编号"
                width="100"
                sortable
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="item_id"
                label="商品id"
                width="100"
                sortable
            >
            </el-table-column>
            <el-table-column
                prop="item_title"
                label="商品标题"
                width="250"
                sortable
            >
            </el-table-column>
             <el-table-column
                prop=""
                label="商品主图"
                width="100"
                sortable
            >
                <template slot-scope="scope">
                    <el-image :src="scope.row.item_pic" :preview-src-list="[scope.row.item_pic]"></el-image>
                </template>
</el-table-column>
<el-table-column prop="price" label="原价" width="" sortable></el-table-column>
<el-table-column prop="coupon_amount" label="优惠券金额" width="" sortable></el-table-column>
<el-table-column prop="end_price" label="券后价" width="" sortable></el-table-column>
<el-table-column label="平台" width="100" sortable>
    <template slot-scope="scope">
        {{scope.row.platform | platform}}
    </template>
</el-table-column>
<el-table-column prop="user_commission" label="用户佣金" sortable></el-table-column>
<el-table-column prop="free_order_point" label="免单点" width="" sortable></el-table-column>
<el-table-column prop="used_num" label="已使用数量" width="" sortable></el-table-column>
<el-table-column prop="total_num" label="商品总数" width="" sortable></el-table-column>
<el-table-column prop="order_num" label="订单数" width="" sortable></el-table-column>
<el-table-column prop="" label="状态" width="" sortable>
    <template slot-scope="scope">
        {{scope.row.status | status}}
    </template>
</el-table-column>
<el-table-column prop="" label="免单类型" width="100" sortable>
    <template slot-scope="scope">
        {{scope.row.free_type | free_type}}
    </template>
</el-table-column>
<el-table-column prop="remark" label="备注" width="200"></el-table-column>
<el-table-column prop="remark" label="开始时间" width="150" sortable>
    <template slot-scope="scope">
        {{scope.row.start_time | dateTime}}
    </template>
</el-table-column>
<el-table-column prop="remark" label="结束时间" width="150" sortable>
    <template slot-scope="scope">
        {{scope.row.end_time | dateTime}}
    </template>
</el-table-column>


<el-table-column label="操作" fixed="right">
    <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="alter(scope.row.id)">修改</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="平台">
            <el-select v-model="platform" placeholder="请选择">
                <el-option v-for="item in platform_list" :key="item.type" :label="item.name" :value="item.type">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="商品ID">
            <el-input v-model="item_id" @change="getGoodsInfo(item_id)" placeholder="请输入商品ID"></el-input>
        </el-form-item>
        <el-form-item label="券后价">
            <el-input v-model="end_price" placeholder="请输入券后价"></el-input>
        </el-form-item>
        <el-form-item label="原价">
            <el-input v-model="price" placeholder="请输入原价"></el-input>
        </el-form-item>
        <el-form-item label="商品标题">
            <el-input v-model="item_title" placeholder="请输入商品标题"></el-input>
        </el-form-item>
        <el-form-item label="优惠券">
            <el-input v-model="coupon_amount" placeholder="请输入优惠券"></el-input>
        </el-form-item>
        <el-form-item label="商品主图">
            <div class="el-form-item__content mgb10">
                <div style="width: 100%;color:transparent">s</div>
                <upLoad dir="image/platform_page" id="up" @url="imgUrl" v-model="item_pic"></upLoad>
            </div>
            <el-input v-model="item_pic"></el-input>
        </el-form-item>
        <el-form-item label="免单点">
            <el-input v-model="free_order_point" placeholder="请输入免单点"></el-input>
        </el-form-item>
        <el-form-item label="已使用数量">
            <el-input v-model="used_num" placeholder="请输入已使用数量"></el-input>
        </el-form-item>
        <el-form-item label="商品总数量">
            <el-input v-model="total_num" placeholder="请输入商品总数量"></el-input>
        </el-form-item>
        <el-form-item label="佣金比例">
            <el-input v-model="rate" placeholder="请输入用户佣金"></el-input>
        </el-form-item>
        <el-form-item label="用户佣金">
            <el-input v-model="user_commission" placeholder="请输入用户佣金"></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="status">
                <el-radio :label="1">未下架</el-radio>
                <el-radio :label="2">已下架</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="免单类型">
            <el-select v-model="free_type" placeholder="请选择">
                <el-option v-for="item in free_type_list" :key="item.type" :label="item.name" :value="item.type">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
            <el-date-picker v-model="start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
            <el-date-picker v-model="end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="注册开始时间">
            <el-date-picker v-model="regist_start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="注册开始时间">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="注册结束时间">
            <el-date-picker v-model="regist_end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="注册结束时间">
            </el-date-picker>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
    </div>
</el-dialog>

</section>
<!-- /.content -->
</template>

<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'freeOrder',
        components: {},

        data() {
            return {
                freeOrderList: [],
                total: 0,
                page: 1,
                pagesize: 10,

                dialog: false,

                search_id: '',
                search_title: '',
                search_platform: '',
                search_status: '',
                search_free_type: '',

                platform_list: common.platform_list,
                free_type_list: common.free_order_type_list,


                loading: true,
                id: '',
                item_id: '',
                end_price: '',
                price: '',
                rate: '',
                item_title: '',
                coupon_amount: '',
                platform: '',
                item_pic: '',
                free_order_point: '',
                used_num: '',
                total_num: '',
                user_commission: '',
                status: 1,
                free_type: '',
                remark: '',
                start_time: '',
                end_time: '',
                regist_start_time: '',
                regist_end_time: '',
            }
        },
        watch: {
            end_price(val) {
                this.user_commission = val
            },
            user_commission(val) {
                this.end_price = val
            },
        },
        filters: {
            status(val) {
                let name = ''
                switch (Number(val)) {
                    case 1:
                        name = '未下架'
                        break;
                    case 2:
                        name = '已下架'
                        break;
                }
                return name
            },
            free_type(val) {
                let name = ''
                if (JSON.stringify(common.free_order_type_list).indexOf(val) != -1) {
                    common.free_order_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            platform(val) {
                let name = ''
                if (JSON.stringify(common.platform_list).indexOf(val) != -1) {
                    common.platform_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getFreeOrder()
        },
        methods: {
            close() {
                this.show = false
                this.getFreeOrder()
            },
            getFreeOrder(page, size) {
                this.loading = true
                api.getFreeOrder({
                    // uid: this.uid,
                    item_id: this.search_id,
                    item_title: this.search_title,
                    platform: this.search_platform,
                    status: this.search_status,
                    free_type: this.search_free_type,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.freeOrderList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getFreeOrder()
            },
            currentChange(val) {
                this.page = val
                this.getFreeOrder()
            },
            seach(page, size) {
                this.getFreeOrder(page, size)
            },
            imgUrl(val) {
                this.item_pic = val
            },
            returnDate(val) {
                let day = new Date(val * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate() + ' ' + day.getHours() + ':' + day.getMinutes() + ':' + day.getSeconds()
            },
            open() {
                this.id = ''
                this.item_id = ''
                this.end_price = ''
                this.price = ''
                this.item_title = ''
                this.coupon_amount = ''
                this.platform = ''
                this.item_pic = ''
                this.free_order_point = ''
                this.used_num = ''
                this.total_num = ''
                this.user_commission = ''
                this.order_num = ''
                this.status = 1
                this.free_type = ''
                this.remark = ''
                this.start_time = ''
                this.end_time = ''
                this.dialog = ''
                this.rate = ''
                this.regist_start_time = ''
                this.regist_end_time = ''
                this.dialog = true
            },
            alter(id) {
                this.id = id
                api.getItemFree({
                    id: id
                }, res => {
                    this.item_id = res.data.item.item_id
                    this.end_price = res.data.item.end_price
                    this.price = res.data.item.price
                    this.item_title = res.data.item.item_title
                    this.coupon_amount = res.data.item.coupon_amount
                    this.platform = res.data.item.platform
                    this.item_pic = res.data.item.item_pic
                    this.free_order_point = res.data.item.free_order_point
                    this.used_num = res.data.item.used_num
                    this.total_num = res.data.item.total_num
                    this.user_commission = res.data.item.user_commission
                        // this.order_num = res.data.item.order_num
                    this.status = Number(res.data.item.status)
                    this.free_type = res.data.item.free_type
                    this.remark = res.data.item.remark
                    this.start_time = this.returnDate(res.data.item.start_time)
                    this.end_time = this.returnDate(res.data.item.end_time)
                    this.regist_start_time = this.returnDate(res.data.item.regist_start_time)
                    this.regist_end_time = this.returnDate(res.data.item.regist_end_time)
                    this.dialog = true
                })

            },
            add() {
                if (this.id != '') {
                    api.alterFree({
                        id: this.id,
                        item_id: this.item_id,
                        end_price: this.end_price,
                        price: this.price,
                        item_title: this.item_title,
                        coupon_amount: this.coupon_amount,
                        rate: this.rate,
                        platform: this.platform,
                        item_pic: this.item_pic,
                        free_order_point: this.free_order_point,
                        used_num: this.used_num,
                        total_num: this.total_num,
                        user_commission: this.user_commission,
                        status: this.status,
                        free_type: this.free_type,
                        remark: this.remark,
                        start_time: this.start_time,
                        end_time: this.end_time,
                        regist_start_time: this.regist_start_time,
                        regist_end_time: this.regist_end_time,
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.dialog = false
                        this.getFreeOrder()
                    })
                    return
                }
                api.addFree({
                    item_id: this.item_id,
                    end_price: this.end_price,
                    price: this.price,
                    item_title: this.item_title,
                    coupon_amount: this.coupon_amount,
                    rate: this.rate,
                    platform: this.platform,
                    item_pic: this.item_pic,
                    free_order_point: this.free_order_point,
                    used_num: this.used_num,
                    total_num: this.total_num,
                    user_commission: this.user_commission,
                    status: this.status,
                    free_type: this.free_type,
                    remark: this.remark,
                    start_time: this.start_time,
                    end_time: this.end_time,
                    regist_start_time: this.regist_start_time,
                    regist_end_time: this.regist_end_time,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialog = false
                    this.getFreeOrder()
                })
            },
            getGoodsInfo(id) {
                api.getGoodsInfo({
                    item_id: id,
                    platform: this.platform,
                }, res => {
                    this.end_price = res.data.end_price
                    this.coupon_amount = res.data.discount_amount
                    this.item_title = res.data.item_title
                    this.item_pic = res.data.item_pic
                    this.price = res.data.price
                    this.user_commission = res.data.end_price
                    this.rate = res.data.rate
                })
            }
        }
    }
</script>
<style scoped>
    .mgb10 {
        margin-bottom: 10px;
    }
    
    .el-form-item {
        text-align: left;
    }
    
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>